<template>
    <el-dialog
        :visible.sync="visible"
        :before-close="closeModal"
        width="600px"
        top="40px"
        :close-on-click-modal="false"
        @open="modalOpen"
        @closed="$emit('closed')"
    >
        <span slot="title">
            <div class="absolute w-full top-0 inset-x-0 border-b rounded-t-lg p-4 bg-gray-100">
                <p>
                    {{ $t('clients.change_holder') }}
                </p>
            </div>
        </span>

        <div class="mb-20 mt-12 px-10">
            <el-form
                ref="form"
                v-loading="$wait.is('loading.employees')"
                class="key-edit-owner-form"
                :model="keyDetails"
                label-position="top"
                element-loading-spinner="el-custom-spinner"
            >
                <el-form-item :label="$t('keys.holder')">
                    <el-select v-model="selectedHolder" :placeholder="$t('keys.select_holder')" filterable>
                        <el-option value="office" :label="$t('keys.at_office')" />
                        <el-option value="client" :label="$t('keys.at_client')" />
                        <el-option-group :label="$t('keys.employees')">
                            <el-option
                                v-for="employee in employees"
                                :key="employee.uuid"
                                :label="employee.name + ' ' + employee.surname"
                                :value="employee.uuid"
                            />
                        </el-option-group>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('keys.replacement')">
                    <el-checkbox v-model="replacement" :disabled="selectedHolder === 'office' || selectedHolder === 'client'">{{ $t(`keys.mark_that_assignment_as_temporary_replacement_system_will_notify_you_if_this_employee_will_not_return_this_key_after_3_days`) }}</el-checkbox>
                </el-form-item>
            </el-form>
        </div>

        <div class="flex justify-end rounded-b-lg border-t w-full p-3 bg-gray-100 absolute bottom-0 inset-x-0">
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
                <el-button type="primary" :loading="$wait.is('updating.holder')" @click="updateHolder">{{ $t('common.update') }}</el-button>
            </span>
        </div>
    </el-dialog>
</template>
<script>
export default {
    props: {
        visible: {
            type:    Boolean,
            default: false,
        },
        keyUuid: {
            type:    String,
            default: null,
        },
    },

    data() {
        return {
            employees:          [],
            keyDetails:         {},
            selectedHolder:     null,
            selectedHolderName: null,
            selectedHolderUuid: null,
            replacement:        false,
        };
    },

    computed: {
        holderName() {
            if (this.selectedHolder === 'client' || this.selectedHolder === 'office') {
                return this.selectedHolder;
            }
            const employeeName = this.employees.filter(employee => employee.uuid === this.holderUuid);

            return `${employeeName[0].name} ${employeeName[0].surname}`;
        },

        holderUuid() {
            if (this.selectedHolder === 'client' || this.selectedHolder === 'office') {
                return null;
            }
            return this.selectedHolder;
        },
    },

    watch: {
        selectedHolder() {
            if (this.selectedHolder === 'office' || this.selectedHolder === 'client') {
                this.replacement = false;
            }
        },
    },

    created() {
    },

    methods: {
        modalOpen() {
            this.getEmployees();
            this.getDetails();
        },

        async getDetails() {
            this.$wait.start('loading.details');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/keys/${this.keyUuid}`);
            
            this.keyDetails = data;

            if (this.keyDetails.holder === 'client' || this.keyDetails.holder === 'office') {
                this.selectedHolder = this.keyDetails.holder;
            } else {
                this.selectedHolder = this.keyDetails.holder_uuid;
            }
            this.$wait.end('loading.details');
        },

        async getEmployees() {
            this.$wait.start('loading.employees');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/employees/employees`);
            console.log('🚀 ~ file: ModalEditHolder.vue ~ line 133 ~ getEmployees ~ data', data);
            this.employees = data;
            this.$wait.end('loading.employees');
        },

        async updateHolder() {
            this.$wait.start('updating.holder');
            try {
                await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/clients/keys/${this.keyUuid}`, {
                    replacement: this.replacement,
                    holder:      this.holderName,
                    holder_uuid: this.holderUuid,
                });
                this.$notify.success({ title: this.$t('common.success') });
                this.closeModal();
                this.$emit('refresh');
            } finally {
                this.$wait.end('updating.holder');
            }
        },

        closeModal() {
            this.$emit('close');
        },
    },
};
</script>
<style>
.key-edit-owner-form .el-select {
    width: 100%;
}

.key-edit-owner-form .el-checkbox {
    display: flex;
    align-items: center;
}

.key-edit-owner-form .el-checkbox__label {
    word-break: break-word;
    white-space: break-spaces;
}
</style>
